// export const NavbarButtonStyle = {
//     width: "auto",
//     height: { lg: "49px", md: "49px", sm: "38px", xs: "33px" },
//     fontSize: { lg: "15px", md: "15px", sm: "12px", xs: "12px" },
//     padding: "1rem",
//     fontWeight: 500,
//     fontFamily: "Montserrat",
//     backgroundColor: "#ffffff", // Initial lighter background (white)
//     color: "#0E4366", // Initial bluish text color
//     border: "1px solid #0E4366", // Initial bluish border
//     "&:hover": {
//       backgroundColor: "#0E4366", // Change to dark blue background on hover
//       color: "#ffffff", // Change text to white on hover
//       borderColor: "#0E4366", // Keep border dark blue on hover
//       fontWeight: 500,
//     },
//     borderRadius: "4px",
//     textTransform: "none",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     boxShadow: "#00000026",
//     position: "relative",
//   };
  

export const NavbarButtonStyle = {
  width: "auto",
  height: { lg: "49px", md: "49px", sm: "38px", xs: "33px" },
  fontSize: { lg: "15px", md: "15px", sm: "12px", xs: "12px" },
  padding: "1rem",
  fontWeight: 500,
  fontFamily: "Montserrat",
  backgroundColor: "#0E4366",
  "&:hover": {
      backgroundColor: "#F6F6F6",
      color: "#0E4366",
      fontWeight: 500
  },
  borderRadius: "4px",
  color: "#ffffff",
  textTransform: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "#00000026",
  position: "relative" // Added position relative here
}