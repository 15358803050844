import S_Img1 from "../../../Assests/Services/Web/S_Img1.png";
import S_Img2 from "../../../Assests/Services/Web/S_Img2.png";
import S_Img3 from "../../../Assests/Services/Web/S_Img3.png";
import S_Img4 from "../../../Assests/Services/Web/S_Img4.png";
import S_Img5 from "../../../Assests/Services/Web/S_Img5.png";
import S_Img6 from "../../../Assests/Services/Web/S_Img6.png";
import S_Img7 from "../../../Assests/Services/Web/S_Img7.png";
import S_Img8 from "../../../Assests/Services/Web/S_Img8.png";
import S_Img9 from "../../../Assests/Services/Web/S_Img9.png";
import Web1 from "../../../Assests/Services/Web/web1.png";
import Web2 from "../../../Assests/Services/Web/web2.png";
import Web3 from "../../../Assests/Services/Web/web3.png";
import Web4 from "../../../Assests/Services/Web/web4.png";
import Web5 from "../../../Assests/Services/Web/web5.png";
import Mob1 from "../../../Assests/Services/Web/Mob1.png";
import Mob2 from "../../../Assests/Services/Web/Mob2.png";
import Mob3 from "../../../Assests/Services/Web/Mob3.png";
import Mob4 from "../../../Assests/Services/Web/Mob4.png";
import Mob5 from "../../../Assests/Services/Web/Mob5.png";
import Prod1 from "../../../Assests/Services/Web/Prod1.png";
import Prod2 from "../../../Assests/Services/Web/Prod2.png";
import Prod3 from "../../../Assests/Services/Web/Prod3.png";
import Prod4 from "../../../Assests/Services/Web/Prod4.png";
import Prod5 from "../../../Assests/Services/Web/Prod5.png";
import Embd1 from "../../../Assests/Services/Web/Embd1.png";
import Embd2 from "../../../Assests/Services/Web/Embd2.png";
import Embd3 from "../../../Assests/Services/Web/Embd3.png";
import Embd4 from "../../../Assests/Services/Web/Embd4.png";
import Embd5 from "../../../Assests/Services/Web/Embd5.png";
import Iot1 from "../../../Assests/Services/Web/Iot1.png";
import Iot2 from "../../../Assests/Services/Web/Iot2.png";
import Iot3 from "../../../Assests/Services/Web/Iot3.png";
import Iot4 from "../../../Assests/Services/Web/Iot4.png";
import Iot5 from "../../../Assests/Services/Web/Iot5.png";
import RD1 from "../../../Assests/Services/Web/R&D1.png";
import RD2 from "../../../Assests/Services/Web/R&D2.png";
import RD3 from "../../../Assests/Services/Web/R&D3.png";
import RD4 from "../../../Assests/Services/Web/R&D4.png";
import RD5 from "../../../Assests/Services/Web/R&D5.png";
import Pcb1 from "../../../Assests/Services/Web/Pcb1.png";
import Pcb2 from "../../../Assests/Services/Web/Pcb2.png";
import Pcb3 from "../../../Assests/Services/Web/Pcb3.png";
import Pcb4 from "../../../Assests/Services/Web/Pcb4.png";
import Pcb5 from "../../../Assests/Services/Web/Pcb5.png";
import Tech1 from "../../../Assests/Services/Web/Tech1.png";
import Tech2 from "../../../Assests/Services/Web/Tech2.png";
import Tech3 from "../../../Assests/Services/Web/Tech3.png";
import Tech4 from "../../../Assests/Services/Web/Tech4.png";
import Tech5 from "../../../Assests/Services/Web/Tech5.png";
import Erp1 from "../../../Assests/Services/Web/Erp1.png";
import Erp2 from "../../../Assests/Services/Web/Erp2.png";
import Erp3 from "../../../Assests/Services/Web/Erp3.png";
import Erp4 from "../../../Assests/Services/Web/Erp4.png";
import Erp5 from "../../../Assests/Services/Web/Erp5.png";

export const WebService = [
  {
    ServiceHeading: ["Web Development Services"],
  },
  {
    ServiceDescription: [
      "We specialize in crafting innovative and responsive web solutions, aligning hardware, embedded systems, and software to bring your ideas to life, employing a tailored agile strategy for accelerated growth and exceptional results in every stage of development.",
    ],
  },
  { ServicePointSvgImage: [Web1, Web2, Web3, Web4, Web5] },
  {
    ServicePointHeading: [
      "1. Custom Web Development:",
      "2. Responsive Design:",
      "3. E-commerce Solutions:",
      "4. Content Management Systems (CMS):",
      "5. Website Maintenance and Support:",
    ],
  },
  {
    ServicePointDescription: [
      // "We build unique websites tailored to your brand, leveraging the latest technologies.",
      // "We ensure your website looks amazing and functions flawlessly across all devices.",
      // "Turn your business into a thriving online store with our secure and scalable solutions.",
      // "Manage your website effortlessly with user-friendly CMS platforms.",
      // "We keep your website secure, updated, and performing at its best.",

      "Unique websites tailored to your brand, leveraging the latest technologies",
      "Flawless functionality and design across all devices",
      "Secure and scalable solutions to turn your business into an online store",
      "Effortless management with user-friendly CMS platforms.",
    ],
  },
  //
  {
    ServiceImage: [S_Img1],
  }, //
  ////////////////////////////////////////1st Component Data ///////////////////////////////////////////////
  {
    ServiceTitle1: ["WEB"],
  },
  {
    ServiceTitle2: ["DEVELOPMENT"],
  },
  {
    ServiceTitleDescription: [
      // "Our specialized web development services integrate state-of-the-art hardware and software innovation.",
      // "Crafting solutions that redefine digital standards with visionary designs and seamless functionality.",
      // "Collaborate with us to shape the future of web technology.",
      // "Prioritizing innovation, excellence, and user-centric design.",
      // "Our specialized web development services blend cutting-edge hardware and software innovations, delivering visionary designs and seamless functionality to redefine digital standards and shape the future of web technology with a focus on user-centric design and excellence."
      "We offer specialized web development services that blend innovative hardware and software to deliver user-centric designs and seamless functionality.",
    ],
  },
];

///////////////////////////////////////////////////////////////////////////////////

export const MobileService = [
  {
    ServiceHeading: ["Mobile Development Services"],
  },
  {
    ServiceDescription: [
      "We specialize in crafting tailored, feature-rich mobile apps, prioritizing user-centric design and technical expertise to exceed expectations, fostering collaboration for a seamless mobile experience that drives success in the digital realm.",
    ],
  },
  { ServicePointSvgImage: [Mob1, Mob2, Mob3, Mob4, Mob5] },
  {
    ServicePointHeading: [
      "1. Custom Mobile App Development:",
      "2. Cross-Platform Development:",
      "3. App UI/UX Design:",
      "4. App Maintenance & Support:",
      "5. Prototype & MVP Development:",
    ],
  },
  {
    ServicePointDescription: [
      // "Tailored mobile solutions for iOS & Android, designed for seamless functionality and user engagement.",
      // "Cost-effective app development reaching multiple platforms with frameworks like React Native & Flutter.",
      // "User-centric UI/UX design that prioritizes usability, accessibility, and visual appeal.",
      // "Keep your app up-to-date, secure, and optimized with our comprehensive maintenance and support services.",
      // "Validate your ideas quickly with rapid prototyping and Minimum Viable Product development.",

      "Tailored mobile solutions for iOS and Android with seamless functionality",
      "Cost-effective app development using frameworks like React Native and Flutter",
      "User-centric UI/UX design prioritizing usability and visual appeal",
      "Comprehensive maintenance and support to keep your app secure and optimized.",
    ],
  },
  //
  {
    ServiceImage: [S_Img2],
  }, //

  ////////////////////////////////////////1st Component Data ///////////////////////////////////////////////

  {
    ServiceTitle1: ["MOBILE APP"],
  },
  {
    ServiceTitle2: ["DEVELOPMENT"],
  },
  {
    ServiceTitleDescription: [
      // "Pursuit of excellence in mobile technology",
      // "Expertise in Android, iOS, and cross-platform solutions",
      // "Utilization of the latest tools and technologies for app development",
      // "Full-cycle process from conceptualization to design, development, and deployment",
      // "Collaborative approach to build robust, scalable, and feature-rich mobile solutions"
      // "Dedicated to excellence in mobile technology, we offer expertise in Android, iOS, and cross-platform solutions, utilizing the latest tools for full-cycle app development from conceptualization to deployment, and fostering collaboration to create robust, scalable, and feature-rich mobile solutions."
      "We provide expert Android, iOS, and cross-platform app development, delivering robust and scalable mobile solutions.",
    ],
  },
];

//////////////////////////////////////////////////////////////////////

export const ProductService = [
  {
    ServiceHeading: ["Product Development Services"],
  },
  {
    ServiceDescription: [
      "We create user-centric software and cutting-edge hardware, driven by innovation and tailored to exceed customer expectations, transforming ideas into standout products in a competitive landscape.",
    ],
  },
  { ServicePointSvgImage: [Prod1, Prod2, Prod3, Prod4, Prod5] },
  {
    ServicePointHeading: [
      "1. Product Strategy",
      "2. UX Design and Prototyping",
      "3. Product Engineering",
      "4. Continuous Integration and Continuous Delivery (CI/CD)",
      "5. Product Management",
    ],
  },
  {
    ServicePointDescription: [
      // "Define product goals, conduct market research, and create a development plan.",
      // "Create intuitive and user-centered product experiences through design and prototyping.",
      // "Build robust software and hardware solutions using cutting-edge technology.",
      // "Automate building, testing, and deployment for rapid delivery.",
      // "Oversee product growth, maintenance, and improvement.",
      "Define product goals and conduct market research to inform a strategic development plan.",
      "Create user-centered product experiences through intuitive design and prototyping.",
      "Develop robust software and hardware solutions, ensuring rapid delivery through automation of building, testing, and deployment.",
    ],
  },
  //
  {
    ServiceImage: [S_Img3],
  }, //
  //////////////////////////////1st Component Data//////////////////////////////////////
  {
    ServiceTitle1: ["PRODUCT"],
  },
  {
    ServiceTitle2: ["DEVELOPMENT"],
  },
  {
    ServiceTitleDescription: [
      // "We prioritize excellence in product development, driven by customer needs, merging creativity and technology to deliver holistic solutions, with consultation services to achieve your goals."

      "We prioritize excellence in product development, merging creativity and technology to deliver customer-focused solutions and consultation services.",
    ],
  },
];

///////////////////////////////////////////////////////////////////////////////////////////////////

export const EmbeddedService = [
  {
    ServiceHeading: ["Embedded Systems"],
  },
  {
    ServiceDescription: [
      "We engineer seamless software for precise hardware, creating innovative embedded systems that redefine industries with reliability and adaptability across diverse sectors.",
    ],
  },
  { ServicePointSvgImage: [Embd1, Embd2, Embd3, Embd4, Embd5] },
  {
    ServicePointHeading: [
      "1. Embedded System Development:",
      "2. Hardware Design and Prototyping:",
      "3. Embedded System Integration and Testing:",
      "4. IoT Solutions Development:",
      "5. Embedded System Consultation and Support:",
    ],
  },
  {
    ServicePointDescription: [
      // "Custom firmware, RTOS, drivers, and applications tailored to your hardware and industry needs.",
      // "Expert PCB design, schematics, and prototyping for optimal integration and functionality.",
      // "Seamless integration of software and hardware with rigorous testing to ensure flawless performance.",
      // "We build the brains behind connected devices, including sensor integration, data acquisition, and cloud connectivity.",
      // "We consult, advise, and support you throughout the development lifecycle, from component selection to post-deployment maintenance.",
      "Custom firmware and applications tailored to your hardware needs",
      "Expert PCB design and prototyping for optimal integration",
      "Comprehensive software and hardware integration with testing for flawless performance",
      "Consultation and support throughout the development lifecycle.",
    ],
  },
  //

  {
    ServiceImage: [S_Img4],
  },
  //////////////////////////1st Component Data//////////////////////////////////

  {
    ServiceTitle1: ["EMBEDDED"],
  },
  {
    ServiceTitle2: ["SYSTEMS"],
  },
  {
    ServiceTitleDescription: [
      // "Expertise in embedded systems where hardware and software intertwine",
      // "Focus on both hardware and software-based embedded systems",
      // "Delivery of innovative products that redefine technological boundaries",
      // "Engineering of cutting-edge embedded systems for diverse devices and machinery",
      // "Specialization in robust, scalable hardware architectures for sophisticated systems",
      // "Commitment to ensuring reliability and optimal performance"

      // "Expert in embedded systems integrating hardware and software, specializing in innovative, reliable, and scalable architectures for diverse devices and machinery."

      "We specialize in embedded systems, integrating hardware and software to create innovative and scalable architectures for various devices.",
    ],
  }, //
];

//////////////////////////////////////////////////////////////////////////////////////////////

export const IotService = [
  {
    ServiceHeading: ["IoT Platform Development"],
  },
  {
    ServiceDescription: [
      "We specialize in seamless IoT integration, empowering businesses with data-driven insights and enhanced experiences, customizing solutions to propel your business into a realm of unparalleled connectivity and growth.",
    ],
  },
  { ServicePointSvgImage: [Iot1, Iot2, Iot3, Iot4, Iot5] },
  {
    ServicePointHeading: [
      "1. Custom IoT Platform Development:",
      "2. IoT Application Development:",
      "3. IoT Security Solutions:",
      "4. IoT Analytics and Insights:",
      "5. IoT Integration and Interoperability:",
    ],
  },
  {
    ServicePointDescription: [
      // "Scalable, secure, and interoperable ecosystems for hardware, software, connectivity, and data.",
      // "User-friendly mobile and web applications to manage, monitor, and control your IoT devices.",
      // "Comprehensive solutions to safeguard your data, including encryption and secure data transmission.",
      // "Leverage data analytics and AI for insights, predictive analysis, and better decision-making.",
      // "Connect diverse devices and systems for smooth communication and data exchange.",
      "Scalable and secure ecosystems for hardware, software, and data",
      "User-friendly applications to manage and control your IoT devices",
      "Comprehensive data protection solutions with encryption and secure transmission",
      "Leverage AI and analytics for insights and predictive analysis.",
     "Connect diverse devices and systems for smooth communication and data exchange.",
    
    ],
  },
  //
  {
    ServiceImage: [S_Img5],
  }, //

  ///////////////////1st Component Data///////////////////////////////

  {
    ServiceTitle1: ["IOT PLATFORM"],
  },
  {
    ServiceTitle2: ["DEVELOPMENT"],
  },
  {
    ServiceTitleDescription: [
      // "At Octathorn, we revolutionize businesses by engineering cutting-edge IoT platforms that redefine connectivity.",
      // "Our approach blends innovation and expertise to deliver bespoke solutions.",
      // "We seamlessly integrate devices, data, and intelligence for optimal performance.",
      // "Harnessing the power of IoT, we craft tailored ecosystems for heightened efficiency and innovation.",
      // "Focused on sustainable growth, we transform your vision into a dynamic reality.",
      // "Leveraging proficiency in IoT technology to build robust, scalable, and secure platforms.",
      // "Driving unparalleled success through advanced IoT solutions."
      // "At Octathorn, we transform businesses with innovative IoT platforms, seamlessly integrating devices and data to create tailored ecosystems that enhance efficiency, drive sustainable growth, and deliver secure, scalable solutions for unparalleled success."
      "At Octathorn, we transform businesses with innovative IoT platforms that integrate devices and data to enhance efficiency and drive sustainable growth.",
    ],
  },
];

///////////////////////////////////////////////////////////////////////////////////////

export const RDService = [
  {
    ServiceHeading: ["Research and Development"],
  },
  {
    ServiceDescription: [
      "We foster a forward-thinking culture that drives pioneering research, leveraging cutting-edge technologies and interdisciplinary approaches to translate ideas into market-ready innovations, fueled by a commitment to delivering tangible outcomes.",
    ],
  },
  { ServicePointSvgImage: [RD1, RD2, RD3, RD4, RD5] },
  {
    ServicePointHeading: [
      "1. Innovation Strategy Development:",
      "2. Product Prototyping and Development:",
      "3. Technology Assessment and Integration:",
      "4. Intellectual Property (IP) Management:",
      "5. Collaborative Research and Partnerships:",
    ],
  },
  {
    ServicePointDescription: [
      // "We craft custom strategies to turn ideas into reality, identifying opportunities for groundbreaking advancements.",
      // "We bring your vision to life, rapidly developing and refining new product concepts.",
      // "We assess and integrate cutting-edge technologies to improve efficiencies and enhance products",
      // "We safeguard your innovations through intellectual property protection strategies.",
      // "We foster partnerships and collaborations to accelerate innovation and solve complex challenges.",
      "Custom strategies to transform ideas into reality and identify opportunities",
      "Rapid development and refinement of new product concepts",
      "Integration of cutting-edge technologies to enhance efficiencies and products",
      "Protection of innovations through intellectual property strategies.",
    ],
  },
  //
  {
    ServiceImage: [S_Img6],
  }, //

  ////////////////////1st Component Data////////////////////////////
  {
    ServiceTitle1: ["RESEARCH AND"],
  },
  {
    ServiceTitle2: ["DEVELOPMENT"],
  },
  {
    ServiceTitleDescription: [
      // "At Octathorn, we champion a culture of innovation through robust Research & Development practices.",
      // "Our R&D approach fosters creativity and pushes boundaries in technology.",
      // "We stay at the forefront of technological advancements to deliver cutting-edge solutions.",
      // "Constantly exploring the unknown and challenging conventions.",
      // "Committed to creating the next generation of solutions that redefine industries.",
      // "Elevating experiences through innovative R&D efforts."
      // "At Octathorn, we cultivate innovation through robust R&D practices that drive technological advancements, challenge conventions, and elevate experiences by creating next-generation solutions that redefine industries."
      "At Octathorn, we foster innovation through robust R&D practices that drive technological advancements and create next-generation solutions.",
    ],
  },
];

////////////////////////////////////////////////////////////////////////////

export const PcbService = [
  {
    ServiceHeading: ["PCB Development"],
  },
  {
    ServiceDescription: [
      "Our PCB development combines expertise and innovation, ensuring meticulous design processes that translate concepts into high-quality layouts, emphasizing functionality, performance, and reliability for electronic devices.",
    ],
  },
  { ServicePointSvgImage: [Pcb1, Pcb2, Pcb3, Pcb4, Pcb5] },
  {
    ServicePointHeading: [
      "1. PCB Design and Layout Services:",
      "2. Prototype and Manufacturing Support:",
      "3. Component Sourcing and Bill of Materials (BOM) Management:",
      "4. Design for Manufacturing (DFM) and Design for Test (DFT) Services:",
      "5. PCB Consultancy and Design Review:",
    ],
  },
  {
    ServicePointDescription: [
      // "We deliver comprehensive PCB design services, translating your vision into high-quality, functional layouts.",
      // "Get support bringing your design to life with prototyping, testing, and manufacturing guidance.",
      // "We help source reliable components and manage your Bill of Materials for cost-effectiveness.",
      // "Our expertise optimizes designs for efficient manufacturing and testing.",
      // "Get expert guidance and design review to elevate your PCB quality.",
      "Comprehensive PCB design services that translate your vision into functional layouts",
      "Support for prototyping, testing, and manufacturing guidance",
      "Sourcing reliable components and managing your Bill of Materials for cost-effectiveness",
      "Expert guidance and design review to elevate PCB quality.",
    ],
  },
  //
  {
    ServiceImage: [S_Img7],
  }, //
  ///////////////////////////////////////1st Component Data///////////////////////////

  {
    ServiceTitle1: ["PCB"],
  },
  {
    ServiceTitle2: ["DEVELOPMENT"],
  },
  {
    ServiceTitleDescription: [
      // "At Octathorn, we take pride in our consultative approach to PCB design.",
      // "Our team provides comprehensive consultancy services with valuable insights and design reviews.",
      // "Focused on optimizing PCB layouts for performance, reliability, and efficiency.",
      // "We strive to exceed expectations with high-quality PCB solutions tailored to client specifications.",
      // "Throughout the development cycle, we prioritize design validation and prototype testing.",
      // "Conducting rigorous testing procedures, including functional testing and design verification.",
      // "Ensuring PCB designs meet stringent quality standards before production."
      // "At Octathorn, we excel in PCB design consultancy, optimizing layouts for performance and reliability while prioritizing design validation and rigorous testing to deliver high-quality, tailored solutions that meet stringent quality standards."
      "At Octathorn, we excel in PCB design consultancy, optimizing layouts for performance while ensuring high-quality, reliable solutions.",
    ],
  },
];

///////////////////////////////////////////////////////////////////////////////////////////////////////

export const TechnicalService = [
  {
    ServiceHeading: ["Technical Consultation"],
  },
  {
    ServiceDescription: [
      "Our seasoned experts offer tailored advice and solutions through a consultative process driven by industry expertise, fostering open communication and partnership for efficient goal achievement.",
    ],
  },
  { ServicePointSvgImage: [Tech1, Tech2, Tech3, Tech4, Tech5] },
  {
    ServicePointHeading: [
      "1. Technology Assessment & Strategy Development:",
      "2. Solution Architecture and Design:",
      "3. Performance Optimization and Troubleshooting:",
      "4. Security and Compliance Consulting:",
      "5. Technology Roadmap and Advisory Services:",
    ],
  },
  {
    ServicePointDescription: [
      // "We analyze your infrastructure, identify gaps, and recommend solutions aligned with your goals.",
      // "We craft scalable and secure software architectures to optimize performance.",
      // "We pinpoint performance issues and implement solutions for peak system efficiency.",
      // "We assess and enhance security, ensuring compliance with regulations.",
      // "We collaborate on tech roadmaps and advise on emerging technologies for growth.",
      "Analysis of infrastructure to identify gaps and recommend aligned solutions",
      "Development of scalable and secure software architectures for optimized performance",
      "Identification and resolution of performance issues for peak system efficiency",
      "Enhancement of security and compliance with regulations.",
    ],
  },
  //
  {
    ServiceImage: [S_Img8],
  }, //

  //////////////////////1st Component Data//////////////////////////////////
  {
    ServiceTitle1: ["TECHNICAL"],
  },
  {
    ServiceTitle2: ["CONSULTATION"],
  },
  {
    ServiceTitleDescription: [
      //  "Technical consultation at Octathorn is a collaborative journey",
      //  "Focus on deeply understanding client needs, challenges, and objectives",
      //  "Active listening to gather insights and requirements",
      //  "Comprehensive analysis of the project's technical landscape"
      // "At Octathorn, our technical consultation is a collaborative journey that emphasizes understanding client needs and challenges through active listening and comprehensive analysis of the project's technical landscape."
      // "At Octathorn, we offer collaborative technical consultation focused on understanding client needs through active listening and thorough project analysis."
      "At Octathorn, we provide collaborative technical consultation to understand client needs through active listening and project analysis.",
    ],
  },
];

///////////////////////////////////////////////////////////////////////////////////////////////////

export const ErpService = [
  {
    ServiceHeading: ["Enterprise Resource Planning"],
  },
  {
    ServiceDescription: [
      "Access unparalleled training, support, and data migration expertise for seamless ERP 	utilization, empowering your teams with confidence, ongoing maintenance, and 	powerful analytics tools for enhanced operational efficiency and strategic insights.",
    ],
  },
  { ServicePointSvgImage: [Erp1, Erp2, Erp3, Erp4, Erp5] },
  {
    ServicePointHeading: [
      "1. Implementation and Integration Services:",
      "2. Customization and Development:",
      "3. Training and Support:",
      "4. Data Migration and Analytics:",
      "5. Consulting and Strategy:",
    ],
  },
  {
    ServicePointDescription: [
      // "We tailor ERP systems to your needs and seamlessly connect them to existing software.",
      // "Extend ERP functionality with custom modules built for your unique processes.",
      // "Empower your team with comprehensive training and ongoing maintenance.",
      // "Migrate data smoothly and leverage built-in tools for data-driven decisions.",
      // "Find the right ERP fit and optimize its use for your business goals.",

      "Tailored ERP systems that seamlessly connect to existing software",
      "Custom modules to extend ERP functionality for unique processes",
      "Comprehensive training and ongoing maintenance for your team",
      "Smooth data migration and tools for data-driven decisions.",
    ],
  },
  //
  {
    ServiceImage: [S_Img9],
  }, //

  //////////////////////////1st Component Data///////////////////////////////////////

  {
    ServiceTitle1: ["ERP"],
  },
  {
    ServiceTitle2: ["SERVICES"],
  },
  {
    ServiceTitleDescription: [
      // "Comprehensive ERP services to revolutionize business operations",
      // "Specialization in seamless ERP implementation and integration",
      // "Tailored ERP systems to meet unique organizational requirements",
      // "Expertise in connecting ERP software with existing systems",
      // "Smooth transition for unified data management and streamlined workflows"
      // "Offering comprehensive ERP services to transform business operations with tailored implementations, seamless integration, and expert connections for unified data management and streamlined workflows."
      "We offer comprehensive ERP services to transform operations with tailored implementations and seamless integration for unified data management.",
    ],
  },
];

//////////////////////////////////////////////////////////////////////////////////////////
