export const HealthCare = [
  {
    ServiceHeading: ["Health Care"]
  },
  {
    ServiceDescription: [
      "We specialize in crafting innovative and responsive web solutions, aligning hardware, embedded systems, and software to bring your ideas to life, employing a tailored agile strategy for accelerated growth and exceptional results in every stage of development."
    ]
  },

  {
    ServicePointDescription: [
      "We build unique websites tailored to your brand, leveraging the latest technologies.",
      "We ensure your website looks amazing and functions flawlessly across all devices.",
      "Turn your business into a thriving online store with our secure and scalable solutions.",
      "Manage your website effortlessly with user-friendly CMS platforms.",
      "We keep your website secure, updated, and performing at its best."
    ]
  },

  ////////////////////////////////////////1st Component Data ///////////////////////////////////////////////
  {
    ServiceTitle1: ["HEALTH"]
  },
  {
    ServiceTitle2: ["CARE"]
  },
  {
    ServiceTitleDescription: [
      // "Octathorn leads with relentless innovation, embracing technology to redefine possibilities.",
      // "Specializes in seamless user experiences through expertise in Front-End and Back-End development..",
      // "Ensures reliability and innovation in every project..",
      // "Committed to technological excellence across Embedded Systems, IoT, PCB, Front-End, Back-End, Mobile, and Database technologies."
   "Octathorn specializes in innovation and seamless user experiences while ensuring reliability across IoT, PCB, Mobile, and Database technologies."
    ]
  }
];

///////////////////////////////////////////////////////////////////////////////////

export const Finance = [
  {
    ServiceHeading: ["Finance"]
  },
  {
    ServiceDescription: [
      "We specialize in crafting tailored, feature-rich mobile apps, prioritizing user-centric design and technical expertise to exceed expectations, fostering collaboration for a seamless mobile experience that drives success in the digital realm."
    ]
  },

  {
    ServicePointDescription: [
      "Tailored mobile solutions for iOS & Android, designed for seamless functionality and user engagement.",
      "Cost-effective app development reaching multiple platforms with frameworks like React Native & Flutter.",
      "User-centric UI/UX design that prioritizes usability, accessibility, and visual appeal.",
      "Keep your app up-to-date, secure, and optimized with our comprehensive maintenance and support services.",
      "Validate your ideas quickly with rapid prototyping and Minimum Viable Product development."
    ]
  },

  ////////////////////////////////////////1st Component Data ///////////////////////////////////////////////

  {
    ServiceTitle1: ["FINANCE"]
  },
  {
    ServiceTitle2: ["INDUSTRIES"]
  },
  {
    ServiceTitleDescription: [
      // "Octathorn leads with relentless innovation, embracing technology to redefine possibilities.",
      // "Specializes in seamless user experiences through expertise in Front-End and Back-End development..",
      // "Ensures reliability and innovation in every project..",
      // "Committed to technological excellence across Embedded Systems, IoT, PCB, Front-End, Back-End, Mobile, and Database technologies."
      "Octathorn specializes in innovation and seamless user experiences while ensuring reliability across IoT, PCB, Mobile, and Database technologies."
   
    ]
  }
];

//////////////////////////////////////////////////////////////////////

export const Defence = [
  {
    ServiceHeading: ["Defence"]
  },
  {
    ServiceDescription: [
      "We create user-centric software and cutting-edge hardware, driven by innovation and tailored to exceed customer expectations, transforming ideas into standout products in a competitive landscape."
    ]
  },

  {
    ServicePointDescription: [
      "Octathorn leads with relentless innovation, embracing technology to redefine possibilities.",
      "Specializes in seamless user experiences through expertise in Front-End and Back-End development..",
      "Ensures reliability and innovation in every project..",
      "Committed to technological excellence across Embedded Systems, IoT, PCB, Front-End, Back-End, Mobile, and Database technologies."
    ]
  },

  //////////////////////////////1st Component Data//////////////////////////////////////
  {
    ServiceTitle1: ["DEFENCE"]
  },
  {
    ServiceTitle2: ["INDUSTRIES"]
  },
  {
    ServiceTitleDescription: [
      // "Octathorn leads with relentless innovation, embracing technology to redefine possibilities.",
      // "Specializes in seamless user experiences through expertise in Front-End and Back-End development..",
      // "Ensures reliability and innovation in every project..",
      // "Committed to technological excellence across Embedded Systems, IoT, PCB, Front-End, Back-End, Mobile, and Database technologies."
      "Octathorn specializes in innovation and seamless user experiences while ensuring reliability across IoT, PCB, Mobile, and Database technologies."
   
    ]
  }
];

///////////////////////////////////////////////////////////////////////////////////////////////////

export const PublicSector = [
  {
    ServiceHeading: ["Public Sector"]
  },
  {
    ServiceDescription: [
      "We engineer seamless software for precise hardware, creating innovative embedded systems that redefine industries with reliability and adaptability across diverse sectors."
    ]
  },

  {
    ServicePointDescription: [
      "Octathorn leads with relentless innovation, embracing technology to redefine possibilities.",
      "Specializes in seamless user experiences through expertise in Front-End and Back-End development..",
      "Ensures reliability and innovation in every project..",
      "Committed to technological excellence across Embedded Systems, IoT, PCB, Front-End, Back-End, Mobile, and Database technologies."
    ]
  },
  //

  //////////////////////////1st Component Data//////////////////////////////////

  {
    ServiceTitle1: ["PUBLIC"]
  },
  {
    ServiceTitle2: ["SECTOR"]
  },
  {
    ServiceTitleDescription: [
      // "Octathorn leads with relentless innovation, embracing technology to redefine possibilities.",
      // "Specializes in seamless user experiences through expertise in Front-End and Back-End development..",
      // "Ensures reliability and innovation in every project..",
      // "Committed to technological excellence across Embedded Systems, IoT, PCB, Front-End, Back-End, Mobile, and Database technologies."
      "Octathorn specializes in innovation and seamless user experiences while ensuring reliability across IoT, PCB, Mobile, and Database technologies."
   
    ]
  } //
];

//////////////////////////////////////////////////////////////////////////////////////////////

export const Manufacturing = [
  {
    ServiceHeading: ["Manufacturing"]
  },
  {
    ServiceDescription: [
      "We specialize in seamless IoT integration, empowering businesses with data-driven insights and enhanced experiences, customizing solutions to propel your business into a realm of unparalleled connectivity and growth."
    ]
  },

  {
    ServicePointDescription: [
      "Scalable, secure, and interoperable ecosystems for hardware, software, connectivity, and data.",
      "User-friendly mobile and web applications to manage, monitor, and control your IoT devices.",
      "Comprehensive solutions to safeguard your data, including encryption and secure data transmission.",
      "Leverage data analytics and AI for insights, predictive analysis, and better decision-making.",
      "Connect diverse devices and systems for smooth communication and data exchange."
    ]
  },

  ///////////////////1st Component Data///////////////////////////////

  {
    ServiceTitle1: ["MANUFACTURING"]
  },
  {
    ServiceTitle2: ["DEVELOPMENT"]
  },
  {
    ServiceTitleDescription: [
      // "Octathorn leads with relentless innovation, embracing technology to redefine possibilities.",
      // "Specializes in seamless user experiences through expertise in Front-End and Back-End development..",
      // "Ensures reliability and innovation in every project..",
      // "Committed to technological excellence across Embedded Systems, IoT, PCB, Front-End, Back-End, Mobile, and Database technologies."
      "Octathorn specializes in innovation and seamless user experiences while ensuring reliability across IoT, PCB, Mobile, and Database technologies."
   
    ]
  }
];

///////////////////////////////////////////////////////////////////////////////////////

export const Telecom = [
  {
    ServiceHeading: ["Telecom"]
  },
  {
    ServiceDescription: [
      "We foster a forward-thinking culture that drives pioneering research, leveraging cutting-edge technologies and interdisciplinary approaches to translate ideas into market-ready innovations, fueled by a commitment to delivering tangible outcomes."
    ]
  },

  {
    ServicePointDescription: [
      "We craft custom strategies to turn ideas into reality, identifying opportunities for groundbreaking advancements.",
      "We bring your vision to life, rapidly developing and refining new product concepts.",
      "We assess and integrate cutting-edge technologies to improve efficiencies and enhance products",
      "We safeguard your innovations through intellectual property protection strategies.",
      "We foster partnerships and collaborations to accelerate innovation and solve complex challenges."
    ]
  },
  //

  ////////////////////1st Component Data////////////////////////////
  {
    ServiceTitle1: ["TELECOM"]
  },
  {
    ServiceTitle2: ["DEVELOPMENT"]
  },
  {
    ServiceTitleDescription: [
      // "Octathorn leads with relentless innovation, embracing technology to redefine possibilities.",
      // "Specializes in seamless user experiences through expertise in Front-End and Back-End development..",
      // "Ensures reliability and innovation in every project..",
      // "Committed to technological excellence across Embedded Systems, IoT, PCB, Front-End, Back-End, Mobile, and Database technologies."
      "Octathorn specializes in innovation and seamless user experiences while ensuring reliability across IoT, PCB, Mobile, and Database technologies."
   
    ]
  }
];

////////////////////////////////////////////////////////////////////////////

export const Ecommerce = [
  {
    ServiceHeading: ["Ecommerce"]
  },
  {
    ServiceDescription: [
      "Our PCB development combines expertise and innovation, ensuring meticulous design processes that translate concepts into high-quality layouts, emphasizing functionality, performance, and reliability for electronic devices."
    ]
  },

  {
    ServicePointDescription: [
      "We deliver comprehensive PCB design services, translating your vision into high-quality, functional layouts.",
      "Get support bringing your design to life with prototyping, testing, and manufacturing guidance.",
      "We help source reliable components and manage your Bill of Materials for cost-effectiveness.",
      "Our expertise optimizes designs for efficient manufacturing and testing.",
      "Get expert guidance and design review to elevate your PCB quality."
    ]
  },
  //

  ///////////////////////////////////////1st Component Data///////////////////////////

  {
    ServiceTitle1: ["ECOMMERCE"]
  },
  {
    ServiceTitle2: ["DEVELOPMENT"]
  },
  {
    ServiceTitleDescription: [
      // "Octathorn leads with relentless innovation, embracing technology to redefine possibilities.",
      // "Specializes in seamless user experiences through expertise in Front-End and Back-End development..",
      // "Ensures reliability and innovation in every project..",
      // "Committed to technological excellence across Embedded Systems, IoT, PCB, Front-End, Back-End, Mobile, and Database technologies."
      "Octathorn specializes in innovation and seamless user experiences while ensuring reliability across IoT, PCB, Mobile, and Database technologies."
   
    ]
  }
];

///////////////////////////////////////////////////////////////////////////////////////////////////////
