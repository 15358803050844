
import React from 'react';
import { Grid, Typography, Box, Container } from '@mui/material';
import { ReactComponent as MissionIcon } from "../../Assests/Company/missionIcon.svg";
import { ReactComponent as VisionIcon } from "../../Assests/Company/visionIcon.svg";

function AboutUs() {
    const themeStyle = {
        heading: {
            color: "#1B2231",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontSize: { lg: "2.5rem", md: "2.3rem", sm: "2rem", xs: "1.25rem" },
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "131.043%",
            textTransform: "capitalize"
        },
        paragraph: {
            color: "#4C5A67",
            fontFamily: "Inter",
            fontSize: { lg: '1rem', md: '1rem', sm: '1rem', xs: '0.875rem' },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%",


        }
    }
    return (
        <Container maxWidth="xl" >
            <Grid container spacing={5} p={2}   >
                <Grid item xs={12} sm={6} pr={2} pl={2} >

                    <Box sx={{ alignItems: 'start', paddingLeft: { lg: "2rem", md: '4rem', sm: '1rem', xs: '1em' }, paddingRight: { lg: "2rem", md: '4rem', sm: '1.5rem', xs: '1rem' } }}>
                        
                        <Typography variant="h2" sx={{ ...themeStyle.heading, textAlign: { lg: 'start', md: 'start', sm: 'start', xs: 'centre' } }}>About Us</Typography>
                        <br/>
                        <Typography variant="body1" sx={{ ...themeStyle.paragraph, textAlign: { lg: 'start', md: 'start', sm: 'start', xs: 'centre' } }}>At Octathorn, we are at the forefront of technology and innovation, driven by our interdisciplinary team dedicated to designing and developing advanced embedded solutions that tackle complex challenges across diverse industries. With an emphasis on scalability and performance, we employ state-of-the-art methodologies to deliver customized, future-ready solutions.</Typography>
                        <br/>
                        <Typography variant="body1" sx={{ ...themeStyle.paragraph, textAlign: { lg: 'start', md: 'start', sm: 'start', xs: 'centre' } }}>Our commitment to quality and reliability has established us as a trusted partner in transformative technology. Through continuous research and development, we consistently exceed industry standards and drive operational excellence. divide this in two small.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} pr={2} pl={2}>

                    <Box sx={{ alignItems: 'start', }}>
                        <Box sx={{ display: 'flex', gap: '2rem', paddingLeft: "1rem" }}>
                            <MissionIcon />
                            <Typography variant="h2" sx={{ ...themeStyle.heading, textAlign: 'start' }}>Mission</Typography>
                        </Box>

                        <Typography variant="body1" sx={{ ...themeStyle.paragraph, textAlign: 'start', paddingLeft: "6rem", paddingRight: { lg: "4rem", md: '4rem', sm: '1.5rem', xs: '4rem' } }}>Our mission is to engineer cutting-edge products and services that exemplify technical excellence and robust operational frameworks, ensuring seamless functionality. We strive to establish ourselves as a comprehensive end-to-end technology solutions provider, adding measurable value to our clients' enterprises.</Typography>
                    </Box>

                    <Box sx={{ alignItems: 'start', mt: 3 }}>
                        <Box sx={{ display: 'flex', gap: '2rem', paddingLeft: "1rem" }}>
                            <VisionIcon />
                            <Typography variant="h2" sx={{ ...themeStyle.heading, textAlign: 'start' }}>Vision</Typography>

                        </Box>
                        <Typography variant="body1" sx={{ ...themeStyle.paragraph, textAlign: 'start', mt: 2, paddingLeft: "6rem", paddingRight: { lg: "4rem", md: '4rem', sm: '1.5rem', xs: '4rem' }, }}>We aim to lead in advancing technology solutions, focusing on driving automation and progress. By developing high-performance products and utilizing state-of-the-art technology, we strive to shape the future of our industry and achieve a transformative impact.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}
export default AboutUs;