import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link as ScrollLink, Events, scrollSpy } from 'react-scroll';
import { ActionButtonStyle } from "../../UI/Buttons/ActionButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    List,
    ListItem,
    Container,
    Fade,
    Box,
} from "@mui/material";
import { Link } from 'react-router-dom';

import "./StickyNavbar.css";


import { ReactComponent as IEPHome } from "../../../Assests/Portfolio/main.svg";

import ServiceLaptopImg from "../../../Assests/Portfolio/projectDetails/mainDesktop.png";
import ServiceTabImg from "../../../Assests/Portfolio/projectDetails/mainTab.png";
import ServiceMobileImg from "../../../Assests/Portfolio/projectDetails/mainMobile.png";



function NewPorjectDetailsMain({ project }) {
    const isLG = useMediaQuery("(min-width: 1280px)");
    const isMD = useMediaQuery("(min-width: 900px) and (max-width: 1279px)");
    const isSM = useMediaQuery("(min-width: 600px) and (max-width: 900px)");
    const isXS = useMediaQuery("(max-width: 599px)");

    //............
    const isMobileView = useMediaQuery("(max-width: 600px)");
    const isTabletView = useMediaQuery("(max-width: 900px)");
    const isVvSmallLaptop = useMediaQuery("(max-width: 999px)");
    const isVerySmallLaptop = useMediaQuery("(max-width: 1450px)");
    const isSmallLaptop = useMediaQuery("(max-width: 1650px)");
    const widthValue = isVvSmallLaptop
        ? "90%"
        : isVerySmallLaptop
            ? "70%"
            : isSmallLaptop
                ? "70%"
                : "100%";

    const marginLeftValue = isVvSmallLaptop
        ? "0rem"
        : isVerySmallLaptop
            ? "0rem"
            : isSmallLaptop
                ? "0rem"
                : "10rem";


    let ServiceImage;

    if (isLG || isMD) {
        ServiceImage = ServiceLaptopImg;
    } else if (isSM) {
        ServiceImage = ServiceTabImg;
    } else if (isXS) {
        ServiceImage = ServiceMobileImg;
    }

    //items handeling
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.getElementById('navbar');
            const sticky = navbar.offsetTop;

            if (window.pageYOffset >= sticky) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });

        scrollSpy.update();

        window.addEventListener('scroll', handleScroll);

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const [selectedItem, setSelectedItem] = useState(null);

    const links = [
        { to: 'overview', offset: -350, text: 'Overview' },
        { to: 'feature-list', offset: -400, text: 'Feature List' },
        { to: 'engagement-model', offset: -200, text: 'Engagement Model' },
        { to: 'faqs', offset: -200, text: 'FAQ’s' },
        { to: 'contact-us', offset: -150, text: 'Contact us' },
    ];

    const [selectedProjectItem, setSelectedProjectItem] = useState('Business Needs');

    const handleItemClick = (item) => {
        setSelectedProjectItem(item);
    };

    const data = [

        {
            item: 'Business Needs',
            points: JSON.parse(project?.bussinues[0]),
            images: project.images
        },

        {
            item: 'Challenges',
            points: JSON.parse(project?.challenges[0])
        },
        {
            item: 'Proposed Solution',
            points: JSON.parse(project?.solutions[0])
        },
        {
            item: 'Objectives Achieved',
            points: JSON.parse(project?.objectives[0])
        },
        {
            item: 'Technologies Used',
            points: JSON.parse(project?.technologies[0])
        }
    ];

    const pointsFont = isTabletView ? "0.8rem" : "1rem"
    return (
        <Grid
            container
            sx={{
                padding: {
                    lg: "2rem 0rem",
                    md: "2rem 0rem",
                    sm: "2rem 1rem",
                    xs: "2rem 1rem",
                },
                marginTop: "2rem",
            }}
        >
            {/* <Box border={"2px solid blue"}> */}

            <Grid
                item
                xs={12}
                lg={12}
                md={12}
                sm={12}
                sx={{
                    ...themeStyles.pageHeading,

                }}

            >

                {project.title}
            </Grid>

            {/* </Box> */}
            <Grid
                item
                container
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { lg: "1.1rem", sm: "1rem", xs: "1rem" },
                    alignItems: { lg: "start", md: "start", sm: "start", xs: "center" },
                    paddingLeft: { lg: "3rem", md: "2rem", sm: "1rem", xs: "0rem" },
                    marginTop: { md: "5rem", xs: "2rem" },
                    justifyContent: {
                        lg: "center",
                        md: "center",
                        sm: "start",
                        xs: "center",
                    },

                    // border: "2px solid black",
                }}
            >
                <Card sx={{ height: '100%', boxShadow: 'none', borderRadius: '0px', background: 'transparent', width: "100%" }}>
                    <Typography sx={{ ...themeStyles.pageHeading, ...themeStyles.projectFeatureHeading }}>PROJECT OVERVIEW</Typography>
                    <CardContent>
                        <Box sx={{ overflowX: "auto", overflowY: "hidden", height: "100%", width: "100%" }}>
                            <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
                                {data?.map((itemData) => (
                                    <Typography key={itemData.item} onClick={() => handleItemClick(itemData.item)} sx={{ padding: '0rem', color: '#1B2231', fontSize: { lg: '1rem', sm: '0.8rem', xs: '0.75rem' }, marginRight: '1rem', fontWeight: selectedProjectItem === itemData.item ? 'bold' : 'normal', borderBottom: selectedProjectItem === itemData.item ? { lg: '4px solid #e9852a', sm: '2px solid #e9852a', xs: '1px solid #e9852a' } : 'none', whiteSpace: { sm: "wrap", xs: "nowrap" } }}>
                                        {itemData.item}
                                    </Typography>
                                ))}
                            </div>
                            <ul style={{ textAlign: 'left', marginLeft: '-0.8rem', }}>
                                {data?.map((itemData) => (
                                    selectedProjectItem === itemData.item && itemData.points.map((point) => (

                                        <li key={point} style={{ listStyle: 'none', fontSize: `${pointsFont}`, display: "flex", alignItems: "center", position: "relative", marginTop: "1rem" }}>
                                            <span style={{ color: '#2490EF', fontSize: '3rem', position: "absolute", top: "-1.4rem", left: "-1rem" }}>&#8226;</span>
                                            {point}
                                        </li>
                                    ))
                                ))}
                            </ul>
                        </Box>
                        <Box
                            sx={{ textAlign: "start" }}
                        >
                            <Link to='/contact' style={{ textDecoration: 'none' }}>
                                <Button variant='contained' sx={{
                                    mt: 3, fontStyle: 'bold', padding: { sm: "1rem 2rem", xs: "0.5rem 1rem" }, fontSize: '0.87rem', background: "#0084F6", '&:hover': {
                                        background: '#0E4366',
                                    },
                                }}
                                >Discuss your project</Button>
                            </Link>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                    // gap: "15px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: { lg: "1rem", md: "1rem", sm: "1rem", xs: "3rem" },
                    // border: "2px solid red",
                }}
            >

                <Fade in={true} timeout={3000}>
                    <Box>
                        {/* <ServiceSvg /> */}
                        <img src={ServiceImage} width={widthValue} alt={project?.title} />
                    </Box>
                </Fade>

            </Grid>
            {/* Last Section */}
            <Grid
                item
                xs={12}
                lg={12}
                md={12}
                sm={12}
                sx={{
                    marginTop: { lg: "5rem", md: "3rem", sm: "2.5rem", xs: "1rem" },
                    padding: "1.5rem 0rem",
                    marginLeft: "-2.5rem", // Cancel out the left padding of the main page container
                    marginRight: "-2.5rem", // Cancel out the right padding of the main page container
                    // width: "100%",  
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",

                }}

                borderTop={"1px solid #CACACA"}
                borderBottom={"1px solid #CACACA"}
                display={{ lg: "unset", md: "unset", sm: "none", xs: "none" }}
                id="navbar"
                className={isSticky ? 'sticky' : ''}
            >

                <ul style={{
                    display: "flex",
                    flexDirection: "row",
                    color: "#757575",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: { lg: "1rem", md: "1rem", sm: "0.7rem", xs: "0.6rem" },
                    fontStyle: "normal",
                    fontWeight: 500,
                    gap: "3rem",
                    listStyle: "none",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                }}>
                    {links.map((link, index) => (
                        <li key={index}>
                            <ScrollLink
                                to={link.to}
                                smooth={true}
                                duration={500}
                                offset={link.offset}
                                spy={true} // Add this
                                onSetActive={() => setSelectedItem(link.to)} // And this
                                style={{
                                    textDecoration: "none",
                                    color: selectedItem === link.to ? '#1B2231' : "#757575",
                                    cursor: "pointer",
                                    borderBottom: selectedItem === link.to ? '3px solid #1B2231' : 'none',
                                }}
                                onClick={() => setSelectedItem(link.to)}
                            >
                                {link.text}
                            </ScrollLink>
                        </li>
                    ))}
                </ul>
            </Grid>
        </Grid>
    );
}


const themeStyles = {

    itemStyle: {
        marginRight: '1rem',
    },
    pageHeading: {
        color: "#1B2231",
        fontFamily: "Montserrat",
        fontSize: { lg: "2.5rem", md: "2rem", sm: "1.5rem", xs: "1.5rem" },
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    projectFeatureHeading: {
        textAlign: "left", marginLeft: { sm: "1rem", xs: "0rem" }, marginBottom: "1.5rem", fontSize: { md: "1.5rem", sm: "1.1rem", xs: "1rem" }
    }
}


export default NewPorjectDetailsMain;
