import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Skeleton from "@mui/material/Skeleton";
import EastIcon from "@mui/icons-material/East";

import {
  Box,
  Card,
  CardContent,
  Typography,
  ListItem,
  ListItemText,
  Container,
  useMediaQuery,
  Grid,
  Button,
} from "@mui/material";

import { ReactComponent as TickIcon } from "../../Assests/home/svgs/tickIcon.svg";

import "./IndustryBar.css";
//accordian imports
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function TechnologiesProjects(state) {
  console.log("technology project division state", state);
  let { heading } = useParams();
  // let heading=category.replace(/-/g, ' ');

  let title1 = state?.state?.state?.[0]?.TechHeading;

  const headingMap = {
    // internetofthings: "IoT",
    // frontend: "Front End",
    // backend: "Back End, Database",
    // embeddedsystem: "Embedded",
    // mobileappdevelopment: "Mobile",
    // pcb: "PCB",
    // database: "Back End, Database"

    "Internet of things": "IoT",
    "Front End": "Front End",
    "Back end": "Back End, Database",
    "Embedded system": "Embedded",
    "Mobile App Development": "Mobile",
    PCB: "PCB",
    Database: "Back End, Database",
  };

  if (headingMap.hasOwnProperty(title1)) {
    title1 = headingMap[title1];
  }

  // console.log('category heading',heading);
  // console.log("category heading", heading);

  console.log("tile in the technology project divisionsss .ss. ", title1);

  const [selectedItem, setSelectedItem] = useState(null);
  const [projectsData, setProjectsData] = useState({});
  const [items, setItems] = useState([]); // Initialize the items
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const handleViewMore = (project) => {
    const titleWithoutSpaces = project.title.replace(/\s/g, "").toLowerCase();
    console.log("here is the state of project..||||", state);
    console.log("here is the project of project..|||", project);
    // navigate(`/project/${titleWithoutSpaces}`, { state: { project } });
    const encodedProject = encodeURIComponent(JSON.stringify(project)); // Encode project data into a string
    const url = `/project/${titleWithoutSpaces}?project=${encodedProject}`; // Pass as a query param

    // Redirect with full page refresh
    window.location.href = url; // This triggers the page refresh
  };
  useEffect(() => {
    axios
      .get("https://octathorn.com/api/project")
      // axios.get('https://octathorn.com/api/project')
      .then((response) => {
        const filteredData = response.data.filter(
          (item) => item.category == title1
        );
        console.log(
          "Back end  response in the tech data sssssssssss.. ",
          response
        );
        const transformedData = filteredData.reduce((acc, item) => {
          if (!acc[item.industry]) {
            acc[item.industry] = [];
          }
          acc[item.industry] = [
            ...acc[item.industry],
            ...item.projects.map((project) => ({
              title: project.title,
              images: project.images,
              description: project.description,
              features: project.features,
              bussinues: project.bussinues,
              challenges: project.challenges,
              solutions: project.solutions,
              objectives: project.objectives,
              technologies: project.technologies,
              category: item.category, // Include the 'category' field
            })),
          ];
          return acc;
        }, {});
        //console.log("project portfolio", transformedData);
        console.log("project portfolio", transformedData);
        setProjectsData(transformedData);
        setSelectedItem(filteredData[0]?.industry);

        // Set the items with the industries from the response
        setItems([...new Set(filteredData.map((item) => item.industry))]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
  }, [title1]);

  console.log(
    projectsData,
    "this is the data of the projects comming from databse "
  );

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const themeStyles = {
    heading: {
      fontSize: { lg: "2.5rem", md: "2.5rem", sm: "2rem", xs: "1.25rem" },
      color: "#1B2231",
      textTransform: "capitalize",
      fontWeight: 600,
    },
    subtitle: {
      fontSize: { lg: "1rem", md: "1rem", sm: "0.875rem", xs: "0.75rem" },
      color: "#4c5a67",
      fontFamily: "Inter",
      fontWeight: 400,
      paddingTop: 2,
      width: {
        lg: "60.25rem",
        md: "52.25rem",
        sm: "90%",
        xs: "90%",
      },
      marginLeft: "auto",
      marginRight: "auto",
    },
    learnMore: {
      color: "#4c5a67",
      fontFamily: "Inter",
      fontSize: {
        lg: "0.875rem",
        md: "0.875rem",
        sm: "0.875rem",
        xs: "0.75rem",
      },
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "1.5625rem",
      cursor: "pointer",
    },
    viewMoreBox: {
      display: "flex",
      textAlign: "center",
      gap: "0.5rem",
      position: "absolute",
      bottom: { md: "0rem", sm: "0rem", xs: "0.5rem" },
      right: { sm: "1rem", xs: "1rem" },
      cursor: "pointer",
      transition: "right 0.3s ease-in-out",
      "&:hover": {
        right: { sm: "0.7rem", xs: "0.8rem" }, // Adjust the right position on hover
      },
    },
    viewMore: {
      color: "#319AC7",
      fontFamily: "Montserrat",
      fontSize: {
        lg: "0.875rem",
        md: "0.875rem",
        sm: "0.875rem",
        xs: "0.75rem",
      },
      fontWeight: 600,
      lineHeight: "1.5625rem",
      cursor: "pointer",
    },
    mediaCard: {
      // width: {
      //     lg: "29.4375rem",
      //     md: "30.4375rem",
      //     sm: "9.4375rem",
      //     xs: "18.125rem",
      // },
      //  height: { lg: "24.975rem", md: "20.375rem", sm: "13rem", xs: "7.125rem" },
      display: "flex",
      boxShadow: "none",
      justifyContent: "center",
      alignItems: "center",
      border: "none",
    },
    contentCard: {
      display: "flex",
      justifyContent: "left",
      textAlign: "left",
      backgroundColor: "#FFF",

      height: "100%",
      maxHeight: "30rem",
      width: { xl: "100%", lg: "100%", md: "100%" },

      flexDirection: "column",
      alignItems: "center",
      boxShadow: "none",
      border: "none",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      transition: "background-image 0.3s",
      "&:hover": {
        backgroundImage: `url(${""})`,
      },
    },

    cardtitle: {
      fontSize: { lg: "0.9rem", md: "0.9rem", sm: "0.8rem", xs: "0.8rem" },
      color: "#1B2231",
      fontWeight: 600,
      fontStyle: "normal",

      marginTop: "0px",
      // lineHeight: "1.96563rem",
      lineHeight: "17px",
      textTransform: "capitalize",

      fontFamily: "Montserrat",
    },
    carddescription: {
      fontSize: { lg: "0.7rem", md: "0.7rem", sm: "0.65rem", xs: "0.65rem" },
      fontWeight: 400,
      fontStyle: "normal",
      // lineHeight: "1.695rem",
      color: "#4c5a67",

      marginTop: "1rem",
      lineHeight: "15px",
      fontFamily: "Inter",
      overflow: { sm: "hidden", xs: "hidden", md: "hidden", lg: "hidden" },
      textOverflow: {
        xs: "ellipsis",
        sm: "ellipsis",
        md: "ellipsis",
        lg: "ellipsis",
      },
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: { xs: 4, sm: 4, md: 5, lg: 5 },
      maxHeight: { sm: "6.6em", xs: "6.6em", md: "10.6em", lg: "10.6em" },
    },
    textarea: {
      width: "100%",
      height: "100px",
      color: "#000",
      borderRadius: "17px",
      backgroundColor: "#0E4366",
    },
    productList: {
      color: "#4c5a67",
      fontSize: { lg: "0.7rem", md: "0.7rem", sm: "0.675rem", xs: "0.65rem" },
      fontWeight: 400,
      fontStyle: "normal",
      // lineHeight: "15px",
      lineHeight: "1.695rem",
      fontFamily: "Inter",
      marginLeft: 1,
      overflow: { sm: "hidden", xs: "hidden", md: "hidden", lg: "hidden" },
      textOverflow: {
        xs: "ellipsis",
        sm: "ellipsis",
        md: "ellipsis",
        lg: "ellipsis",
      },
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: { xs: 1, sm: 1, md: 1, lg: 1 },
    },
    listDivider: {
      display: "flex",
      flexDirection: "row",
      flexWrap: { sm: "nowrap", xs: "nowrap", md: "nowrap", lg: "nowrap" },
    },

    accordioHeading: {
      color: "#0E4366",
      fontFamily: "Montserrat",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      textTransform: "uppercase",
    },
    accordionContentDescription: {
      color: "#4c5a67",
      fontFamily: "Inter",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 400,
    },
  };
  const isMobileTabletView = useMediaQuery("(max-width: 600px)");

  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: {
          xs: "0.5rem 1.5rem", // Increased for extra small screens
          sm: "4rem 0rem", // Increased for small screens
          lg: "2.5rem 0rem", // Increased for large screens
        },
        width: "100%",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          marginBottom: "0.7rem",
          // fontSize: {
          //   lg: "1.875rem",
          //   md: "1.875rem",
          //   sm: "1.375rem",
          //   xs: "1.125rem",
          // },
          color: "#1B2231",
          display: "flex",

          textAlign: "center",
          alignItems: "center",
          fontSize: { lg: "18px", md: "16px", sm: "10px", xs: "4px" },
          fontWeight: 600,
          fontFamily: "Montserrat",
          Width: "100%",
        }}
      >
        Featured Projects
      </Typography>

      {loading ? (
        // <CircularProgress />

        <>
          <Skeleton height="60px" animation="wave" />
          <Skeleton height="60px" animation="wave" />
          <Skeleton height="60px" animation="wave" />
          <Skeleton height="60px" animation="wave" />
          <Skeleton height="60px" animation="wave" />
        </>
      ) : (
        <>
          {!isMobileTabletView ? (
            <Box
            // component="div"
            // sx={{ ...themeStyles.listDivider, flex: "nowrap" , justifyContent: "flex-start"}}
            >
              <Box
                component="div"
                sx={{ ...themeStyles.listDivider, flex: "nowrap" }} // Matching the style here
              >
                {/* Industry bar remains unchanged */}
                {items.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    selected={selectedItem === item}
                    onClick={() => handleItemClick(item)}
                    sx={{
                      ...themeStyles.listItemText, // Keep common styles
                      fontFamily: "inter",
                      position: "relative",
                      borderBottom:
                        selectedItem === item ? "none" : "1px solid #A7A7A7", // Similar borderBottom logic
                      color: selectedItem === item ? "#1B2231" : "#A7A7A7", // Similar color logic
                      "&:hover": {
                        backgroundColor: "rgba(14, 67, 102, 0.06)", // Hover state remains the same
                      },
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: "5%",
                        width: "90%",
                        borderBottom:
                          selectedItem === item ? "3px solid #1B2231" : "none", // Bottom line on selection
                      },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              ...themeStyles.listItemText,
                              fontSize: {
                                xs: "0.5rem",
                                sm: "0.5rem",
                                md: "0.6rem",
                                lg: "0.6rem",
                              }, // Font size remains similar
                              fontWeight:
                                selectedItem === item ? "bold" : "none", // Bold on selection
                            }}
                          >
                            {item}
                          </Typography>
                        }
                      />
                    </div>
                  </ListItem>
                ))}
              </Box>

              <Grid
                container
                spacing={1}
                ml={"auto"}
                marginTop={0.7}
                sx={{ 
                  // maxHeight: "45vh", 
                  maxHeight: {
                    xl: "38vh",
                    lg: "50vh",
                    md: "48vh",
                    sm: "45vh",
                    xs: "85vh",
                  },

                  height: "100%", 
                  overflowY: "auto" 
                
                }}
              >
                {selectedItem &&
                  projectsData[selectedItem].map((project, projectIndex) => (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={10.5}
                        md={9.5}
                        lg={6}
                        mt={1}
                        sx={{}}
                      >
                        <Card
                          className="card"
                          sx={{
                            ...themeStyles.contentCard,
                            backgroundImage: `url(${project.images[0]})`,
                            border: "1px solid #ccc", // Change color as needed
                            borderRadius: "8px", // Adjust border radius for a box shape
                            position: "relative", // To position the overlay text absolutely
                            overflow: "none", // To ensure the overlay does not overflow the card
                            width: "auto", // Set a fixed width for the card
                            height: "240px", // Set a fixed height for the card
                            "&:hover .overlay": {
                              opacity: 1, // Show overlay text on hover
                            },
                          }}
                        >
                          {/* Overlay Text */}
                          <Box
                            className="overlay"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: "rgba(0, 0, 0, 0.8)", // Dark shadow with opacity
                              color: "white", // Text color
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              opacity: 0, // Initially hidden
                              transition: "opacity 0.3s ease", // Smooth transition for opacity
                              textAlign: "center", // Center text
                              padding: "1rem", // Optional padding for better spacing
                              zIndex: 1, // Ensure overlay is above the content
                              cursor: "pointer",
                            }}
                          >
                            <Typography variant="h6">
                              {project.title}
                            </Typography>

                            <Typography
                              variant="body2"
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3, // Limit to 3 lines, adjust as needed
                              }}
                            >
                              {project.description}
                            </Typography>

                            {/* Button */}
                            <Box sx={{ marginTop: "1rem" }}>
                              <Button
                                onClick={() => handleViewMore(project)} // Ensure the button click works
                                sx={{
                                  width: { sm: "170px", xs: "150px" },
                                  height: { sm: "49px", xs: "43px" },
                                  fontSize: {
                                    lg: "15px",
                                    md: "15px",
                                    sm: "15px",
                                    xs: "13px",
                                  },
                                  transition: "0.25s",
                                  "&:hover": {
                                    backgroundColor: "#F6F6F6",
                                    borderColor: "#0E4366",
                                    color: "#0E4366",
                                    boxShadow:
                                      "0 0.5em 0.5em -0.4em rgba(255, 255, 255, 0.7)",
                                    transform: "translateY(-0.25em)",
                                  },
                                  border: "1px solid #F6F6F6",
                                  fontFamily: "Montserrat",
                                  borderWidth: "1px",
                                  color: "#ffffff",
                                  textTransform: "none",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  zIndex: 2, // Ensure button is above the overlay
                                }}
                              >
                                View More
                              </Button>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    </>
                  ))}
              </Grid>
            </Box>
          ) : (
            // </Box>
            <Box mt={5}>
              {Object.entries(projectsData).map(
                ([industry, projects], index) => (
                  <Accordion
                    key={index}
                    style={{
                      minHeight: "4.375rem",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon style={{ color: "#0E4366" }} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={themeStyles.accordioHeading}>
                        {industry}
                      </Typography>
                    </AccordionSummary>
                    {projects.map((data, projectIndex) => (
                      <AccordionDetails key={projectIndex}>
                        <Typography
                          sx={{ ...themeStyles.cardtitle, textAlign: "left" }}
                        >
                          {data.title}
                        </Typography>
                        <Typography
                          sx={{
                            ...themeStyles.accordionContentDescription,
                            textAlign: "left",
                          }}
                        >
                          {data.description}
                        </Typography>
                        {/* Products LISTS*/}
                        <Box
                          sx={{
                            // display: "grid",
                            // gridTemplateColumns: "repeat(2, 1fr)",
                            // gap: "20px",
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10%",
                          }}
                        >
                          {data?.features
                            ?.slice(0, 2)
                            ?.map((feature, index) => {
                              const trimmedFeature = feature
                                .split(":")[0]
                                .trim();
                              return (
                                <Box
                                  key={index}
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <TickIcon />
                                  <Typography sx={themeStyles.productList}>
                                    {trimmedFeature}
                                  </Typography>
                                </Box>
                              );
                            })}
                        </Box>
                        {/*LISTS*/}
                        <Box
                          sx={{
                            marginLeft: "1%",
                            marginTop: "2%",
                            width: "100%",
                            contain: "content",
                          }}
                        >
                          <Card sx={{ ...themeStyles.card }}>
                            <Carousel
                              showThumbs={false}
                              showStatus={false}
                              infiniteLoop={true}
                              autoPlay={true}
                              interval={3000}
                              showArrows={false}
                            >
                              {data.images?.map((image, index) => (
                                <div key={index}>
                                  <img
                                    src={image}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                    alt={`IMAGE ${index}`}
                                  />
                                </div>
                              ))}
                            </Carousel>
                          </Card>
                        </Box>
                      </AccordionDetails>
                    ))}
                  </Accordion>
                )
              )}
            </Box>
          )}
        </>
      )}
    </Container>
  );
}

export default TechnologiesProjects;
