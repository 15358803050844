import React, { useState } from "react";
import {
  EmbeddedService,
  ErpService,
  IotService,
  MobileService,
  PcbService,
  ProductService,
  RDService,
  TechnicalService,
  WebService,
} from "../../../Components/ServicesComponents/SecondServiceComps/ServiceData";
import { useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Styles = {
  verticalLine: {
    width: "4px",
    height: "80%",
    backgroundColor: "#0B3B5A",
    position: "absolute",
    left: "0",
    top: "10%",
  },
  text: {
    // whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "auto", // Slightly smaller font size
    color: "#0E4366",
    paddingRight: "auto", // Add more space for the chevron to the right
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: "5.2px 16px", // Adjust padding for more text space
    backgroundColor: "#F6F6F6",
    marginBottom: "2px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  icon: {
    minWidth: "auto", // Let the icon take minimal width to avoid space hogging
    paddingLeft: "8px", // Provide some space between the text and the chevron icon
  },
};

function Sidebar() {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active link

  const services = [
    { service: ProductService, label: "Product Development" },
    { service: EmbeddedService, label: "Embedded System" },
    { service: IotService, label: "IOT Platform Development" },
    { service: RDService, label: "Research and Development" },
    { service: PcbService, label: "PCB" },
    { service: ErpService, label: "ERP" },
    { service: WebService, label: "Web Applications" },
    { service: MobileService, label: "Mobile Application" },
    { service: TechnicalService, label: "Technical Consultation" },
  ];

  const handleClick = (service, index) => {
    navigate('.', { state: service }); // Pass state without changing the URL
    setActiveIndex(index); // Set the clicked index as active
  };

  return (
    <div
      style={{
        width: "100%", // Full width of the sidebar
        padding: "14px", // Padding for the sidebar
        position: "relative", // Positioning for the sidebar
        top: "-6px", // Adjust based on your navbar height
        padding: "14px", // Padding for the sidebar
      }}
    >
      <List sx={{ padding: 0 }}>
        {services.map(({ service, label }, index) => (
          <ListItem
            key={index}
            button
            onClick={() => handleClick(service, index)}
            sx={{
              ...Styles.listItem,
              backgroundColor:
                activeIndex === index
                  ? "rgba(14, 67, 102, 0.06)" // Active background color
                  : undefined,
            }}
          >
            {activeIndex === index && <div style={Styles.verticalLine} />} {/* Vertical line */}
            <ListItemText fontSize="small" primary={label} sx={Styles.text} /> {/* Apply styles for text */}
            <ListItemIcon sx={Styles.icon}> {/* Adjust icon placement */}
              <ChevronRightIcon  /> {/* Smaller icon size */}
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default Sidebar;
