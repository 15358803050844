import React, { useState } from "react";
import {
  HealthCare,
  Finance,
  Defence,
  PublicSector,
  Telecom,
  Manufacturing,
  Ecommerce,
} from "../../Components/Industries/IndustryData";
import { useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight"; // Importing the icon

const Styles = {
  verticalLine: {
    width: "3px", // Width of the vertical line
    height: "80%", // Height of the vertical line
    backgroundColor: "#0B3B5A", // Darker color for the vertical line
    marginRight: "5px", // Closer spacing between line and text
    position: "absolute", // Positioning the vertical line
    left: "-5px", // Adjust to align with the text
    top: "50%", // Center the line vertically
    transform: "translateY(-50%)", // Adjust vertical centering
  },
};

function Sidebar() {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active link

  const Industries = [
    { service: HealthCare, label: "Health Care" },
    { service: Finance, label: "Finance" },
    { service: Defence, label: "Defence" },
    { service: PublicSector, label: "Public Sector" },
    { service: Telecom, label: "Telecom" },
    { service: Manufacturing, label: "Manufacturing" },
    { service: Ecommerce, label: "Ecommerce" },
  ];

  const handleClick = (service, index) => {
    const headingWithoutSpaces = service[0].ServiceHeading[0]
      .replace(/\s/g, "")
      .toLowerCase();
    // navigate(`/industries/${headingWithoutSpaces}`, { state: service });
    navigate('.', { state: service }); // Pass state without changing the URL
    setActiveIndex(index); // Set the clicked index as active
  };

  return (
    <div
      style={{
        width: "100%", // Adjust as needed
        height: "auto", // Full height of the viewport
        padding: "14px", // Padding for the sidebar
        position: "relative", // Fixed position
        top: "-6px", // Start below the navbar (adjust based on your navbar height)
      }}
    >
      <List sx={{ padding: 0 }}>
        {Industries.map(({ service, label }, index) => (
          <ListItem
            key={index}
            button
            onClick={() => handleClick(service, index)}
            sx={{
              marginBottom: "3px",
              position: "relative", // Set position for the line
              backgroundColor:
                activeIndex === index
                  ? "rgba(14, 67, 102, 0.06)"
                  : "#F6F6F6", // Default background color
              "&:hover": {
                backgroundColor: activeIndex === index
                  ? "rgba(14, 67, 102, 0.06)"
                  : "rgba(0, 0, 0, 0.05)", // Hover effect
              },
            }}
          >
            {activeIndex === index && <div style={Styles.verticalLine} />}
            <ListItemText primary={label} sx={{ color: "#0E4366" }} />
            <ListItemIcon sx={{ color: "#0E4366", marginLeft: "20px" }}>
              <ChevronRightIcon />
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default Sidebar;
