import React, { useState } from "react";
import {
  DatabaseTech,
  FrontEndTech,
  BackEndTech,
  MobTech,
  EmbeddedTech,
  PcbTech,
  IotTech,
} from "../../Components/Technologies/TechnologyData";
import { useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function Sidebar() {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active link

  const technologies = [
    { service: EmbeddedTech, label: "Embedded System" },
    { service: IotTech, label: "Internet Of Things" },
    { service: PcbTech, label: "PCB" },
    { service: FrontEndTech, label: "Front End" },
    { service: BackEndTech, label: "Back End" },
    { service: MobTech, label: "Mobile App Development" },
    { service: DatabaseTech, label: "Database" },
  ];

  const handleClick = (service, index) => {
    const headingWithoutSpaces = service[0].TechHeading[0]
      .replace(/\s/g, "")
      .toLowerCase();

    // navigate(`/technologies/${headingWithoutSpaces}`, { state: service });
    navigate('.', { state: service }); // Pass state without changing the URL
    setActiveIndex(index); // Set the clicked index as active
    console.log("the active link is .... ", index);
  };

  return (
    <div
      style={{
        width: "100%", // Adjust as needed
        height: "auto", // Full height of the viewport minus the navbar height (assuming navbar height is 64px)
        padding: "14px", // Padding for the sidebar
        position: "relative", // Fixed position
        top: "-6px", // Start below the navbar (adjust based on your navbar height)
      }}
    >
      <List sx={{ padding: 0 }}>
        {technologies.map(({ service, label }, index) => (
          <ListItem
            key={index}
            button
            onClick={() => handleClick(service, index)} // Pass the index
            sx={{
              position: "relative",
              marginBottom: "3px",
              backgroundColor:
                activeIndex === index ? "rgba(14, 67, 102, 0.06)": "transparent", // Highlight active link
              "&:hover": {
                backgroundColor:
                activeIndex === index
                ? "rgba(14, 67, 102, 0.06)" // Active background color
                : undefined,
              },
            }}

            
          >
            {activeIndex === index && (
              <div
                style={{
                  position: "absolute",
                  left: "0", // Position the line to the left of the text
                  height: "90%", // Adjust the height of the vertical line
                  width: "4px", // Width of the vertical line
                  backgroundColor: "#0E4366", // Color for the vertical line
                }}
              />
            )}
            <ListItemText primary={label} sx={{ color: "#0E4366" }} />
            <ListItemIcon sx={{ color: "#0E4366", marginLeft: "20px" }}>
              <ChevronRightIcon />
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default Sidebar;
