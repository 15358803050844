import { useMediaQuery, Box, Container } from "@mui/material";

import { useLocation, useParams } from "react-router-dom";

import { useState } from "react";
import topBGTab from "../Assests/home/topBgTab.png";
import topBGMobile from "../Assests/home/topRightbgMobile.png";
import topRightBg from "../Assests/Services/Web/ServiceBg.png";

import ContactRefer from "../Components/ContactUs/ContactRefer";
import {
  DatabaseTech,
  FrontEndTech,
  BackEndTech,
  MobTech,
  EmbeddedTech,
  PcbTech,
  IotTech
} from "../Components/Technologies/TechnologyData";
import { useEffect } from "react";
import Parenttechnologies from "../Components/Technologies/ParentTechnologies";

function StartTechnologies() {
  const { heading } = useParams();

  const [techState, setTechState] = useState(null);
  const [techuseState, setTechuseState] = useState(null);

  const getTechnologyState = () => {
    switch (heading) {
      case "embeddedsystem":
        setTechState(EmbeddedTech);
        setTechuseState("Raspberry Pi");
        break;

      case "internetofthings":
        setTechState(IotTech);
        setTechuseState("Azure IoT Hub");
        break;

      case "pcb":
        setTechState(PcbTech);
        setTechuseState("Altium");
        break;

      case "frontend":
        setTechState(FrontEndTech);
        setTechuseState("Angular");
        break;

      case "backend":
        setTechState(BackEndTech);
        setTechuseState("Node JS");
        break;

      case "mobileappdevelopment":
        setTechState(MobTech);
        setTechuseState("Flutter");
        break;

      case "database":
        setTechState(DatabaseTech);
        setTechuseState("FireBase");
        break;

      default:
        setTechState(EmbeddedTech);
        setTechuseState("Raspberry Pi");
        break;
    }
  };

  useEffect(() => {
    getTechnologyState(heading);
  }, []);

  const location = useLocation();

  const stateObject = {
    techState: techState,
    techuseState: techuseState
  };

  const { state } = location.state || { state: stateObject } || { state: null };

  if (state) {
    // const firstServiceHeading = state[0]?.ServiceHeading[0];
    // const firstService = state[0];
    // console.log(firstService);
    // const serviceHeading = firstService?.ServiceHeading;
    // console.log({ serviceHeading });
  }

  //console.log(techState, "heading of object...>")
  const isMobileView = useMediaQuery("(max-width: 600px)");
  const isTabletView = useMediaQuery("(max-width: 900px)");

  return (
    <>
      <Box
        width="100%"
         maxHeight={"90%"}
      height= "90%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
        sx={{
          backgroundColor: "#F6F6F6",
          contain: { xs: "content", sm: "none" }
        }}
      >
        {isMobileView ? (
          <img
            src={topBGMobile}
            alt="Octathorn technologies Hardware & Software"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              right: "0",
              top: 0,
              zIndex: 0
            }}
          />
        ) : isTabletView ? (
          <img
            src={topBGTab}
            alt="Octathorn technologies Hardware & Software"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              top: 0,
              zIndex: 0
            }}
          />
        ) : (
          <img
            src={topRightBg}
            alt="Octathorn technologies Hardware & Software"
            width={"18%"}
            style={{
              display: "flex",
              overflow: "hidden",
              justifyContent: "flex-end",
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 0
            }}
          />
        )}
        <Container
          maxWidth="false"
          sx={{
            padding: { xs: "0rem", sm: "1rem 0rem", lg: "0rem 0rem" },

            zIndex: 1
          }}
        >
          <Parenttechnologies state={state || location.state} />

          <div
            style={{
              backgroundColor: "#FFF",
              marginLeft: "-2rem",
              marginRight: "-2rem",
              marginBottom: "-1rem"
            }}
          >
            {/* <ContactRefer /> */}
          </div>
        </Container>
      </Box>
    </>
  );
}

export default StartTechnologies;
