import ProjectDetail from "../Components/Portfolio/ProjectDetail";
import EngagementModel from "../Components/EngagementModel/EngagementModel"
import WhyClientsChooseUs from "../Components/WhyClientsChooseUs/WhyClientsChooseUs";
import { Box, Container } from "@mui/system";
import { useLocation } from 'react-router-dom';
import ContactProject from "../Components/ContactUs/ContactProject";
import ProjectOverview from "../Components/Portfolio/ProjectDetails/ProjectOverview";
import FeatureList from "../Components/Portfolio/ProjectDetails/FeatureList";
import PorjectDetailsMain from "../Components/Portfolio/ProjectDetails/PorjectDetailsMain";
import ControlledAccordions from "../Components/UI/Accordion/ControlledAccordion";
import ContactRefer from "../Components/ContactUs/ContactRefer";
import NewProjectDetailMain from "../Components/Portfolio/ProjectDetails/NewProjectDetailsMain";
import NewProjectOverview from "../Components/Portfolio/ProjectDetails/NewProjectOverview";
import NewTechStack from "../Components/Technologies/NewTechStack"
import CardCarousel from "../Components/UI/Cards/CardCarousel";
import LastServiceComp from "../Components/ServicesComponents/LastServiceComp";
import TechnologyStack from "../Components/Home/TechnologyStack";
function ProjectDetails() {
  const location = useLocation(); // Get the current location object
  const queryParams = new URLSearchParams(location.search); // Create URLSearchParams instance

  // Retrieve the 'project' query parameter and parse it
  const project = queryParams.get('project') 
    ? JSON.parse(decodeURIComponent(queryParams.get('project'))) 
    : null;
  // const project = location.state.project;
  const category = project.category
  return (
    <Box

      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
      sx={{ backgroundColor: "#F6F6F6", mt: 3, contain: { xs: "content", sm: 'none' } }}

    >
      <Container
        maxWidth="false"
        sx={{ padding: { xs: "1.125rem 2.5rem", sm: "1.125rem 2.5rem", lg: "1.125rem 2.5rem" } }}

      >
        {/* <ProjectDetail project={project} /> */}
        {/* <PorjectDetailsMain project={project} /> */}
        <NewProjectDetailMain project={project} />
        <div id="overview">
          {/* <ProjectOverview project={project} /> */}
          <NewProjectOverview project={project} />
        </div>
        <div id="technologies">
          <NewTechStack title={"Project Stack"} category={category} />
        </div>
        <div id="feature-list" style={{ marginTop: '5%' }}>
          <FeatureList project={project} />
        </div>

        <div id="faqs" style={{ marginTop: '5%' }}>
          <ControlledAccordions category={category} />
        </div>

        <CardCarousel />{/*Reviews */}

        <div style={{ marginTop: "0%" }}>
          <LastServiceComp />
        </div>
        <div id="engagement-model" style={{ marginTop: '5%' }}>
          <EngagementModel />
        </div>



        <div id="contact-us" style={{ marginTop: '8%', backgroundColor: "#FFF", marginLeft: "-2rem", marginRight: "-2rem", marginBottom: "-1rem" }}>
          <ContactRefer />
        </div>


      </Container>
    </Box>
  );
}

export default ProjectDetails;