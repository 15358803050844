import React from "react";
import Avatar from "@mui/material/Avatar";
import "./EngagementModel.css";
import "../UI/Buttons/ButtonHover.css"
import { ActionButtonStyle } from "../UI/Buttons/ActionButton";

import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Box, height, padding } from "@mui/system";
import tag from "../../Assests/tag.svg";
import hiring from "../../Assests/hiring.svg";
import time from "../../Assests/time-management.svg";

const data = [
  {
    id: 1,
    name: "Fixed Price Engagement",
    description: [
      "Ideal for small projects with defined budgets.",
      "Resource allocation, time, and costs are agreed upon before the project starts.",
      "Low-risk model due to predefined costs and timelines.",
    ],
    image: tag ,
  },
  {
    id: 2,
    name: "Dedicated Resource Allocation",
    description: [
      "Best suited for long-term and scalable projects.",
      "Provides complete control over processes and resource management.",
      "Ensures dedicated resources with an assured commitment.",
    ],
    image: hiring,
  },
  {
    id: 3,
    name: "Time & Material Contracting",
    description: [
      "Suitable when the project scope is not fully defined.",
      "Allows adjustment of resources based on the evolving project lifecycle.",
      "Offers flexibility for clients to explore and experiment with new ideas.",
    ],
    image: time,
  },

  // Add more data objects for other persons
];

const EngagementModel = () => {
  const backgroundColors = ["#319AC7", "#0E4366"];
  const isMobileView = useMediaQuery("(max-width: 480px)");
  const isTabletView = useMediaQuery("(max-width: 768px)");

  return (
    <Grid
      container
      sx={{
        padding: {
          lg: "2rem 3rem",
          md: "2rem 0rem",
          sm: "2rem 0rem",
          xs: "1rem 1rem",
        },
        backgroundColor: "#F6F6F6",
      }}
    >
      
      {/* First Section */}
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: "3rem",
          padding: "1rem 1rem",
          // border: "1px solid red",
        }}
      >
        <br />
        <br />
        <Typography
          variant="h1" component="h1"
          sx={{
            color: "#1B2231",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            fontSize: { lg: ' 2.5rem', md: '2rem', sm: '1.5rem', xs: '1.5rem' },
            fontWeight: 600,
            fontFamily: "Montserrat",
            Width: "100%",
          }}
        >
          Business-Oriented Engagement Models
        </Typography>
        
      </Grid>

      {/* Second Section */}

      {data.map((person, index) => (
        <Grid
          key={person.id}
          item
          lg={4}
          md={4}
          sm={4}
          xs={12}
          sx={{
            // border: "1px solid blue",
            padding: {
              lg: "1rem 1.3rem",
              md: "1rem 1rem",
              sm: "1rem 0.5rem",
              xs: "1rem 3rem",
            },
            position: "relative",
          }}
        >
         <Card
  sx={{
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    border: "2px solid transparent",
    transition: "border-color 0.3s ease-in-out", // Smooth border transition
    "&:hover": {
      borderColor: "#62C4EA", // Border color changes on hover
       cursor: "pointer"
    },
  }}
>
  <Avatar
    alt={person.name}
    sx={{
      width: { lg: "100px", md: "90px", sm: "80px", xs: "75px" },
      height: { lg: "100px", md: "90px", sm: "80px", xs: "75px" },
      marginTop: "-2rem",
      transform: {
        lg: "auto",
        md: "auto",
        sm: "auto",
        xs: "auto",
      },
      position: "absolute",
      alignItems: "center",
      backgroundColor: backgroundColors[person.id % backgroundColors.length],
    }}
  >
    <img
      style={{
        height: "60%",
        width: "55%",
        transform: person.image === tag ? "scale(1.8)" : "none", // Apply zoom only for the `tag` image
        transition: "transform 0.3s ease-in-out", // Smooth transition for zoom effect
      }}
      src={person.image}
      alt={person.name}
    />
  </Avatar>

  <CardContent
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
    }}
  >
    <Typography
      variant="h3"
      component="h3"
      sx={{
        fontFamily: "Montserrat",
        marginTop: {
          lg: "6rem",
          md: "6rem",
          sm: "4rem",
          xs: "3.5rem",
        },
        fontSize: {
          lg: "24px",
          md: "18px",
          sm: "14px",
          xs: "12px",
        },
        fontWeight: 600,
        color: "#1B2231",
      }}
    >
      {person.name}
    </Typography>

    {person.description.map((point, index) => (
      <Box
        key={index}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          margin: {
            lg: "1rem",
            md: "1rem",
            sm: "0.5rem",
            xs: "0.5rem",
          },
          height: "100%",
          width: "100%",
          contain: "content",
        }}
      >
        <Box sx={{ paddingLeft: "0.5rem" }}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10.5" cy="10.5" r="10.5" fill="#62C4EA" fillOpacity="0.65" />
            <path
              d="M5 10.6L7.85714 13L15 7"
              stroke="#0E4366"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>

        <Typography
          variant="p"
          component="p"
          color="#4c5a67"
          sx={{
            fontFamily: "Inter",
            fontSize: {
              lg: "16px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            fontWeight: 400,
            textAlign: "left",
            minHeight: "90%",
            paddingLeft: "1rem",
          }}
        >
          {point}
        </Typography>
      </Box>
    ))}
  </CardContent>
</Card>



        </Grid>


      ))}
      {/* 3rd Section */}
      <Grid
        item
        xs={12}
        sx={{
          marginTop: "3rem",
          //   padding: "1rem 1rem",
          // border: "1px solid green",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a href="/contact" style={{ textDecoration: "none", position: "relative" }}>
          <Button
            sx={ActionButtonStyle}
            className="bttn"
          >
            <span className="btt">Talk to Our Team</span>
          </Button>
        </a>
      </Grid>
    </Grid>
  );
};

export default EngagementModel;
